import request from '@/utils/request'


// 查询活动分类列表
export function listType(query) {
  return request({
    url: '/activity/type/list',
    method: 'get',
    params: query
  })
}

// 查询活动分类分页
export function pageType(query) {
  return request({
    url: '/activity/type/page',
    method: 'get',
    params: query
  })
}

// 查询活动分类详细
export function getType(data) {
  return request({
    url: '/activity/type/detail',
    method: 'get',
    params: data
  })
}

// 新增活动分类
export function addType(data) {
  return request({
    url: '/activity/type/add',
    method: 'post',
    data: data
  })
}

// 修改活动分类
export function updateType(data) {
  return request({
    url: '/activity/type/edit',
    method: 'post',
    data: data
  })
}

// 删除活动分类
export function delType(data) {
  return request({
    url: '/activity/type/delete',
    method: 'post',
    data: data
  })
}
